<script lang="ts" setup>
  import { RouteRecord } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { RouteRecordId } from '~/model/RouteRecord/RouteRecord'
  import UiIcon from '~/components/Ui/UiIcon/UiIcon.vue'
  import useUiIcon from '~/composables/UiIcon/UiIcon'
  import { computed, useRoute, watch } from '#imports'
  import { UiIconId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useTranslation from '~/composables/Translation/Translation'
  import useGameCinemaRoom from '~/composables/GameCinemaRoom/GameCinemaRoom'
  import useGameCity from '~/composables/GameCity/GameCity'
  import useGameInteraction from '~/composables/GameInteraction/GameInteraction'
  import useGameLocation from '~/composables/GameLocation/GameLocation'
  import useGameDistrict from '~/composables/GameDistrict/GameDistrict'
  import { GameHierarchyId } from '~/model/GameHierarchy/GameHierarchy'
  import { GameInteractionId } from '~/model/GameInteraction/GameInteraction'
  import { GameLocationId } from '~/model/GameLocation/GameLocation'
  import { GameDistrictId } from '~/model/GameDistrict/GameDistrict'
  import { GameCityId } from '~/model/GameCity/GameCity'
  import { GameCinemaRoomId } from '~/model/GameCinemaRoom/GameCinemaRoom'
  import { useGameBreadcrumbStore } from '~/store/GameBreadcrumb'
  import { useGameStateStore } from '~/store/GameState'
  import { GameModeId } from '~/model/GameMode/GameMode'
  import useNotification from '~/composables/Notification/Notification'
  import { useGameProgressStore } from '~/store/progress/GameProgress'
  import DisplayCurrencyStay from '~/components/DisplayCurrencyStay/DisplayCurrencyStay.vue'
  import DisplayCurrencyExplore from '~/components/DisplayCurrencyExplore/DisplayCurrencyExplore.vue'

  const gameStateStore = useGameStateStore()
  const gameBreadcrumbStore = useGameBreadcrumbStore()
  const gameProgressStore = useGameProgressStore()

  const { activeHierarchyId, breadcrumb } = storeToRefs(gameBreadcrumbStore)
  const { unlockedTrouvailleCount } = storeToRefs(gameProgressStore)
  const { activeModeId } = gameStateStore
  const route = useRoute()
  const { toGameCinemaRoom } = useGameCinemaRoom()
  const { toGameCity } = useGameCity()
  const { toGameDistrict } = useGameDistrict()
  const { toGameLocation } = useGameLocation()
  const { toGameInteraction } = useGameInteraction()
  const { openNotification } = useNotification()

  const { t } = useTranslation()
  const { findIcon } = useUiIcon()

  const showBackAction = computed<boolean>(() => {
    return [
      RouteRecordId.GameCinemaRoom,
      RouteRecordId.GameCinemaSection,
      RouteRecordId.GameCinemaStorage,
    ].includes(route.name as RouteRecordId)
  })

  const backLink = computed<RouteRecord>(() => {
    if (route.name === RouteRecordId.GameCinemaSection) {
      return toGameCinemaRoom(breadcrumb.value.cinemaRoomId as GameCinemaRoomId)
    }

    switch (activeHierarchyId.value) {
      case GameHierarchyId.Interaction:
        return toGameInteraction(breadcrumb.value.interactionId as GameInteractionId)
      case GameHierarchyId.Location:
        return toGameLocation(breadcrumb.value.locationId as GameLocationId)
      case GameHierarchyId.District:
        return toGameDistrict(breadcrumb.value.districtId as GameDistrictId)
      case GameHierarchyId.City:
        return toGameCity(breadcrumb.value.cityId as GameCityId)
      default:
        return toGameCity()
    }
  })

  const iconBaseId = computed(() => findIcon({ id: UiIconId.IconCoreCyanNegCinema }))
  const iconHoverId = computed(() => findIcon({ id: UiIconId.IconCoreWhiteNegCinema }))

  watch(unlockedTrouvailleCount, (newValue, oldValue) => {
    if (newValue > oldValue) {
      openNotification('overlay-notification-trouvaille-found', 4000)
    }
  })
</script>

<template>
  <div class="c-navigation-cinema u-typography-root">
    <ul class="c-navigation-cinema__list u-reset">
      <li class="c-navigation-cinema__entry">
        <DisplayCurrencyStay v-if="activeModeId === GameModeId.Stay" />
        <DisplayCurrencyExplore v-if="activeModeId === GameModeId.Explore" />
      </li>
      <li class="s-header-entry">
        <template v-if="showBackAction">
          <NuxtLink :to="backLink" class="btn btn--small btn--primary">
            {{ t('navigation-game.back') }}
          </NuxtLink>
        </template>
        <template v-else>
          <NuxtLink
            :to="toGameCinemaRoom()"
            class="s-header-entry__wrap s-header-entry__link u-reset"
          >
            <div class="s-header-entry__icon-box">
              <UiIcon
                :id="iconBaseId"
                :size="UiIconSizeId.Large"
                class="s-header-entry__icon s-header-entry__icon--base"
              />
              <UiIcon
                :id="iconHoverId"
                :size="UiIconSizeId.Large"
                class="s-header-entry__icon s-header-entry__icon--hover"
              />
            </div>
            <div class="s-header-entry__description">
              {{ t('navigation-game.cinema') }}
            </div>
          </NuxtLink>
        </template>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/util/color/color' as col;

  .c-navigation-cinema {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 0 24px;
    color: col.$monochrome-white;
  }

  .c-navigation-cinema__list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 24px;
  }
</style>
